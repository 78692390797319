import { IPage } from "@/models/IPage";
import { IPageResponse, Result } from "@/models/IPageWiseResponse";
import { IResponse } from "@/models/IResponse";
import axios from "axios";
import auhtHeader from "./auth-header";
import getBaseURL from "./Base";

let base = getBaseURL();

const API_URL = base.baseURL;
const userId = base.userId;
const clientId = base.clientId;

class WebPageService {
  page!: IPage;
  lstPages: IPage[] = [];
  responsedata: any;

  savePage(model: IPage, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "page/savepagecontent", model, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getdashboardInfo(key: string): any {
    this.responsedata = axios
      .get<IResponse>(API_URL + "page/getdashboardinfo", {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  deletePage(pageId:number, key: string): Promise<IResponse> {
    this.responsedata = axios
      .post<IResponse>(API_URL + "page/deletepage", {Id:pageId}, {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getListofPages(
    pageIndex: number, key: string,
    search: string = ""
  ): Promise<Result> {
    var URL =
      API_URL + "page/getallpages/" + pageIndex;

    if (search.length > 0) {
      URL =
        API_URL +
        "page/getallpages/" +
        pageIndex +
        "?search=" +
        search;
    }

    this.responsedata = axios
      .get<IPageResponse>(URL, { headers: auhtHeader(key) })
      .then((response) => {
        const results = response.data.result;

        return results;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getPageServiceType(key: string): any {
    this.responsedata = axios
      .get<IResponse>(API_URL + "page/getpagetype", {
        headers: auhtHeader(key),
      })
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }

  getPageByPageId(pageId: number, key: string): Promise<IPage> {
    this.responsedata = axios
      .get<IResponse>(
        API_URL +
          "page/getpagebyid?id=" +
          pageId,
        { headers: auhtHeader(key) }
      )
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        console.log(error);
      });

    return this.responsedata;
  }
}

export default new WebPageService();
