import Vue from "vue";
import Options from "vue-class-component";
import HelloWorldComponent from "@/components/HelloWorld/index.vue";
import AdminLayout from "@/layouts/Admin/index.vue";
import { VueEditor } from "vue2-editor"
import WebPageService from "@/services/WebPageService";
import { namespace } from "vuex-class";
import { Watch } from "vue-property-decorator";

const Auth = namespace("Auth");

@Options({
  components: {
    AdminLayout,
    VueEditor
  },
})
export default class Dashboard extends Vue {
  dasboardTileData: any = {};
  search=""
  content="<h1>Some initial content</h1>"


  @Auth.Getter
  private getBranchId!: string;

  async created() {
    this.dasboardTileData = await WebPageService.getdashboardInfo(this.getBranchId);
    // console.log(this.dasboardTileData);

  }

  @Watch("getBranchId", { deep: true })
  async onBranchPropertyChanged(value: string, oldValue: string) {
    (this as any).$Progress.start();
    this.dasboardTileData = await WebPageService.getdashboardInfo(this.getBranchId);
    (this as any).$Progress.finish();

  }


}
